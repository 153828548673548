<table mat-table [dataSource]="orderHistory">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{ element.timestamp | dateFormat }} </td>
  </ng-container>

    <ng-container matColumnDef="market">
      <th mat-header-cell *matHeaderCellDef> Market </th>
      <td mat-cell *matCellDef="let element"> {{ element.marketName  || '-' }} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let element"> {{ element.props.amount }} </td>
    </ng-container>

    <ng-container matColumnDef="price" >
      <th mat-header-cell *matHeaderCellDef> Price </th>
      <td mat-cell *matCellDef="let element"> {{ element.props.price }} </td>
    </ng-container>

    <ng-container matColumnDef="isBuy" >
      <th mat-header-cell *matHeaderCellDef> Buy/Sell </th>
      <td mat-cell *matCellDef="let element"> {{ element.action }} </td>
    </ng-container>
        
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{ element.state || '-'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>