<mat-card color='accent' >
    <mat-card-title>
        <div class="header-wrapper">
          <div class="icons">
            <!-- <mat-icon svgIcon="resize"></mat-icon>
            <mat-icon svgIcon="close"></mat-icon> -->
          </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <mat-tab-group selectedIndex='0' mat-align-tabs="left">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Positions'> 
                        Positions
                        <!-- <span class='num'> {{ allPositionsLength }} </span> -->
                    </span>
                </ng-template>
                <tl-futures-positions></tl-futures-positions>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Orders'> 
                        Orders 
                        <span class='num'>{{ allOrdersLength }}</span>
                    </span>
                </ng-template>
                <tl-futures-orders></tl-futures-orders>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Orders History'>
                        Orders History
                        <span class='num'> {{ allOrderHistoryLength }} </span>
                    </span>
                </ng-template>
                    <tl-futures-order-history></tl-futures-order-history>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Trade History'>
                        Trade History
                        <span class='num'> {{ 0 }} </span>
                    </span>
                </ng-template>
                    <tl-futures-trade-history></tl-futures-trade-history>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Channels Commits'>
                        Channels Commits
                        <span class='num'> {{ allCommitsLength }} </span>
                    </span>
                </ng-template>
                <tl-futures-commits></tl-futures-commits>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>