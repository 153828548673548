<mat-card>
  <mat-card-title>
    <div class="header-wrapper">
      <div class="title-text">
        <span>Order Book</span>
        <span class='contract'></span>
      </div>
      <div class="icons">
        <!-- <mat-icon svgIcon="settings"></mat-icon>
        <mat-icon svgIcon="resize"></mat-icon>
        <mat-icon svgIcon="close"></mat-icon> -->
      </div>
    </div>
  </mat-card-title>
    <mat-card-content>
        <div class="sell" #sellOrdersContainer>
          <table mat-table [dataSource]="sellOrderbooks">
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Price</th>
              <td mat-cell *matCellDef="let el"> {{el.price.toFixed(4)}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let el"> {{el.amount.toFixed(4)}} </td>
            </ng-container>
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let el"> {{(el.price * el.amount).toFixed(4)}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)='fillBuySellPrice(row.price)'
            ></tr>
          </table>
        </div>
        <div class="middle">
          <span class='market-price'>{{ marketPrice.toFixed(4) }}</span>
          <span class="last-price"> {{ lastPrice.toFixed(2) }} </span>
          <mat-icon [ngStyle]='{"color": upTrend ? "green" : "red"}'>{{ upTrend ? "arrow_upward" : "arrow_downward" }}</mat-icon>
         </div>
         <div class="buy">
          <table mat-table [dataSource]="buyOrderbooks">
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Price</th>
              <td mat-cell *matCellDef="let el"> {{el.price.toFixed(4)}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let el"> {{el.amount.toFixed(4)}} </td>
            </ng-container>
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let el">  {{(el.price * el.amount).toFixed(4)}}  </td>
            </ng-container>
            <tr 
              mat-row
              *matRowDef="let row; columns: displayedColumns;"
              (click)='fillBuySellPrice(row.price)'
            ></tr>
          </table>
        </div>
    </mat-card-content>
</mat-card>