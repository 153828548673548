<table mat-table [dataSource]="relayedHistory">
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let element"> {{ element.time | dateFormat }} </td>
    </ng-container>

    <ng-container matColumnDef="side">
        <th mat-header-cell *matHeaderCellDef> Side </th>
        <td mat-cell *matCellDef="let element"> {{ element.side }} </td>
    </ng-container>

    <ng-container matColumnDef="buyer">
        <th mat-header-cell *matHeaderCellDef> Buyer </th>
        <td mat-cell *matCellDef="let element">
            <span (click)="copy(element.buyer.keypair.address)">{{ element.buyer.keypair.address | shortAddress }}</span>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="seller">
        <th mat-header-cell *matHeaderCellDef> Seller </th>
        <td mat-cell *matCellDef="let element">
            <span (click)="copy(element.seller.keypair.address )">{{ element.seller.keypair.address | shortAddress }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount" >
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element">
            <span>{{ element.props.amountDesired }} {{ selectedMarket.first_token.shortName }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="price" >
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let element">
            <span>{{ getPrice(element)}} {{ selectedMarket.second_token.shortName }}</span>
        </td>
    </ng-container>
  
    <ng-container matColumnDef="total" >
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let element">
            <span>{{ element.props.amountForSale }} {{ selectedMarket.second_token.shortName }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="txid" >
        <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
        <td mat-cell *matCellDef="let element"> 
            <span (click)="copy(element.txid)">{{ element.txid | shortAddress }}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>