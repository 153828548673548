<div class="dialog-container">
    <h1>Create Config File</h1>
    <mat-dialog-content class="mat-typography">
        <div class="content-container">
            <div class="directory">
                {{ path.length > 20 ? "..." + path.slice(-30) + '\\litecoin.conf' : path  + '\\litecoin.conf' }}
            </div>
            <!-- <mat-form-field class="example-form-field">
                <mat-label>Port</mat-label>
                <input matInput type="number" [(ngModel)]='port'>
            </mat-form-field>     -->
            <mat-form-field class="example-form-field">
                <mat-label>Useername</mat-label>
                <input matInput type="text" [(ngModel)]='username'>
            </mat-form-field>    
    
            <mat-form-field class="example-form-field">
                <mat-label>Password</mat-label>
                <input matInput type="password" [(ngModel)]='password'>
            </mat-form-field>

            <mat-form-field class="example-form-field">
                <mat-label>Re-Password</mat-label>
                <input matInput type="password" [(ngModel)]='repassword'>
            </mat-form-field>
            <button
                [disabled]='buttonDisabled'
                mat-button
                (click)='create()'
                class='create-button'
            >Create</button>
        </div>
    </mat-dialog-content>
</div>