<table mat-table [dataSource]="openedPositions">
    <ng-container matColumnDef="market">
      <th mat-header-cell *matHeaderCellDef> Market </th>
      <td mat-cell *matCellDef="let element"> {{ marketName  || '-'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> Position </th>
      <td mat-cell *matCellDef="let element"> {{ element['position'] || '-' }} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef> Entry Price</th>
      <td mat-cell *matCellDef="let element"> {{ element['entry_price'] || '-' }} </td>
    </ng-container>

    <ng-container matColumnDef="liquidation" >
      <th mat-header-cell *matHeaderCellDef> Liquidation Price </th>
      <td mat-cell *matCellDef="let element"> {{element['BANKRUPTCY_PRICE'] || '-' }} </td>
    </ng-container>

    <ng-container matColumnDef="margin" >
        <th mat-header-cell *matHeaderCellDef> Margin </th>
        <td mat-cell *matCellDef="let element"> {{ element['position_margin'] || '-'}} </td>
      </ng-container>

    <ng-container matColumnDef="upnl" >
      <th mat-header-cell *matHeaderCellDef> UPNL </th>
      <td mat-cell *matCellDef="let element"> {{ element['upnl'] || '-' }} </td>
    </ng-container>
    <ng-container matColumnDef="close" >
      <th mat-header-cell *matHeaderCellDef> Close </th>
      <td mat-cell  *matCellDef="let element">
        <mat-icon class='close-icon' (click)='closePosition(element)'> close </mat-icon> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  