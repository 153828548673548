import { Component } from '@angular/core';

@Component({
  selector: 'tl-spot-trading-grid',
  templateUrl: './spot-trading-grid.component.html',
  styleUrls: ['./spot-trading-grid.component.scss']
})
export class SpotTradingGridComponent {
    
}
