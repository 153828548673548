import { Component } from '@angular/core';

@Component({
  selector: 'tl-futures-trading-grid',
  templateUrl: './futures-trading-grid.component.html',
  styleUrls: ['../../spot-page/spot-trading-grid/spot-trading-grid.component.scss']
})
export class FuturesTradingGridComponent {
    
}
