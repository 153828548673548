import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './tx-builder-page.component.html',
  styleUrls: ['./tx-builder-page.component.scss']
})

export class TxBuilderPageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {

  }
  
}
