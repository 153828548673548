<mat-grid-list cols='5' gutterSize='10' rowHeight="240px" class="md-layout md-alignment-top-center">
    <mat-grid-tile colspan='1' rowspan='3'>
        <tl-futures-buy-sell-card></tl-futures-buy-sell-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='1' rowspan='2'>
        <tl-futures-orderbook-card></tl-futures-orderbook-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='2' rowspan='2'>
        <tl-futures-chart-card></tl-futures-chart-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='1' rowspan='2'>
        <tl-futures-history-card></tl-futures-history-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='4' rowspan='1'>
        <tl-futures-bottom-card></tl-futures-bottom-card>
    </mat-grid-tile>
</mat-grid-list>