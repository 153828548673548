<mat-toolbar>
    <mat-toolbar-row>
        <div class="left-side">
            <button mat-icon-button (click)='toggleSideBar()'>
                <mat-icon>menu</mat-icon>
                <img src='./assets/tl-logo.svg'>
            </button>
        </div>
        <span class="spacer"></span>
        <div class="right-side" *ngIf="isLoggedIn">
            <div class="balance" (click)='updateBalance()'>
                <span>Balance: </span>
                <span class="balance-span" *ngIf="!balanceLoading">
                    {{ availableBalance }} LTC
                </span>
                <span class="balance-span" *ngIf="balanceLoading">
                    <mat-spinner diameter="25"></mat-spinner>
                </span>
            </div>
        </div>
        
       <div class="right-side">
          <div class="connect">
            <div>
              <button 
                id="connectWalletButton" 
                class="connect-button" 
                *ngIf="!walletAddress" 
                (click)="connectWallet()">
                Connect Wallet
              </button>
              <div *ngIf="walletAddress" class="wallet-address-display">
                Connected: <span id="connectedAddress">{{ walletAddress | slice:0:6 }}...{{ walletAddress | slice:-6 }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <button mat-icon-button (click)='isLoggedIn ? logOut() : navigateToLoginRoute()'>
            <mat-icon>{{ isLoggedIn ? 'logout' : 'login' }}</mat-icon>
        </button> -->
    </mat-toolbar-row>
</mat-toolbar>

<div class="nav-container">
    <nav mat-tab-nav-bar mat-align-tabs="center">
        <a
            *ngFor='let route of mainRoutes'
            mat-tab-link
            (click)='navigateTo(route)'
            [active]='selectedRoute === route'
            [disabled]='route.disabled'
        >{{ route.name }}</a>
      </nav>
</div>