<div class="tx-builde-wrapper">
    <mat-tab-group
        dynamicHeight 
        class='main-market-nav'
        mat-align-tabs="center"
    >
        <mat-tab label="Build">
            <tl-build-tab></tl-build-tab>
        </mat-tab>
        <mat-tab label="Sign">
            <tl-sign-tab></tl-sign-tab>
        </mat-tab>
        <mat-tab label="Send">
            <tl-send-tab></tl-send-tab>
        </mat-tab>
        <mat-tab label="Decode">
            <tl-decode-tab></tl-decode-tab>
        </mat-tab>
    </mat-tab-group>
</div>