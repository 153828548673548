<mat-card color='accent' >
    <mat-card-title>
        <div class="header-wrapper">
          <div class="icons">
            <!-- <mat-icon svgIcon="resize"></mat-icon>
            <mat-icon svgIcon="close"></mat-icon> -->
          </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <mat-tab-group selectedIndex='0' mat-align-tabs="left">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Orders'> 
                        Orders 
                        <span class='num'>{{ openedOrders }}</span>
                    </span>
                </ng-template>
                <tl-spot-orders></tl-spot-orders>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Order History'>
                        Order History
                        <span class='num'> {{ orderHistory }} </span>
                    </span>
                </ng-template>
                <tl-spot-order-history></tl-spot-order-history>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Trade History'>
                        Trade History
                        <span class='num'> {{ tradeHistory }} </span>
                    </span>
                </ng-template>
                <tl-spot-trade-history></tl-spot-trade-history>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span matTooltip='Channels Commits'>
                        Channels Commits
                        <span class='num'> {{ channelCommits }} </span>
                    </span>
                </ng-template>
                <tl-spot-channels></tl-spot-channels>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>