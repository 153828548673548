<div class="dialog-container">
    <div class="content">
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" [(ngModel)]='password'>
        </mat-form-field>
    </div>
    <div class="buttons">
        <button mat-button [mat-dialog-close]="password" cdkFocusInitial color="primary">VALIDATE</button>
        <button mat-button (click)="close()" color="warn">CANCLE</button>
    </div>
</div>