<div class="dialog-container">
    <h1>Select Network</h1>
    <mat-dialog-content class="mat-typography">
        <div class="content-container">
            <mat-form-field appearance="fill" >
                <mat-label>Network</mat-label>
                <mat-select [(ngModel)]="network">
                    <mat-option value="LTC">Litecoin</mat-option>
                    <mat-option value="LTCTEST">Litecoin Testnet</mat-option>
                </mat-select>
            </mat-form-field>
            <button
                style='margin: 0.5rem 0;'
                class='greenButton'
                mat-button
                (click)='selectNetwork()'
            >Select Network</button>
        </div>
    </mat-dialog-content>
</div>