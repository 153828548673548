<table mat-table [dataSource]="activeChannelsCommits">
    <ng-container matColumnDef="property">
        <th mat-header-cell *matHeaderCellDef> Token/property ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.tokenName }} / {{ element.propertyId }} </td>
    </ng-container>
    
    <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef> Sender </th>
        <td mat-cell *matCellDef="let element">
          <span (click)="copy(element.sender)">{{ element.sender | shortAddress }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="channel" >
        <th mat-header-cell *matHeaderCellDef> Channel </th>
        <td mat-cell *matCellDef="let element"> 
          <span (click)="copy(element.channel)">{{ element.channel | shortAddress }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount" >
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let element"> {{ element.amount }} </td>
    </ng-container>
  
    <ng-container matColumnDef="block" >
      <th mat-header-cell *matHeaderCellDef> Block </th>
      <td mat-cell *matCellDef="let element"> {{ element.block }} </td>
    </ng-container>

    <ng-container matColumnDef="actions" >
      <th mat-header-cell *matHeaderCellDef> Close </th>
      <td mat-cell  *matCellDef="let element">
        <mat-icon class='close-icon' (click)='closeCommit(element)'> close </mat-icon> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>