<mat-card>
  <mat-card-title>
    <div class="header-wrapper">
      <div class="title-text">
        <span>Buy/Sell</span>
        <span class='contract'>-</span>
      </div>
      <div class="icons">
        <!-- <mat-icon svgIcon="resize"></mat-icon>
        <mat-icon svgIcon="close"></mat-icon> -->
        <!-- <div class="on-chain">
          <mat-slide-toggle
            color='primary'
            labelPosition="before"
          >On-Chain</mat-slide-toggle>
        </div> -->
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="order-type">
      <div class="order-type-buttons">
        <p
          [ngClass]="{ selected: isLimitSelected }"
          (click)="isLimitSelected = true"
        >Limit</p>
        <p
          [ngClass]="{ selected: !isLimitSelected }"
          (click)="isLimitSelected = false"
        >Market</p>
      </div>
    </div>
    <div class="buy-sell-form" [formGroup]='buySellGroup'>
      <div class="custom-form-field" *ngIf='isLimitSelected'>
        <div class="prefix">Price</div>
        <input type='number' formControlName="price">
        <div class="suffix">{{ selectedMarket.second_token.shortName }}</div>
      </div>
      <div class="custom-form-field">
        <div class="prefix">Amount</div>
        <input type='number' formControlName="amount">
        <div class="suffix">{{ selectedMarket.first_token.shortName }}</div>
      </div>
    </div>
    <div class="buy-sell-info">
      <div class="column">
        <span>Fee: {{ getFees(true).toFixed(6) }} LTC </span>
      </div>
      <div class="column">
        <span>Fee: {{ getFees(false).toFixed(6) }} LTC </span>
      </div>
    </div>
    <div class="buttons">
      <button
        class='buy-button'
        (click)='handleBuySell(true)'
        [disabled]='getButtonDisabled(true)'
      >Buy</button>
      <button
        class='sell-button'
        (click)='handleBuySell(false)'
        [disabled]='getButtonDisabled(false)'
      >Sell</button>
    </div>
    <div class="buy-sell-info max" *ngIf="spotAddress">
      <div class="column" (click)="fillMax(true)">
        <span>Max: {{ getMaxAmount(true).toFixed(6) }} {{ this.selectedMarket.second_token.shortName }} </span>
      </div>
      <div class="column" (click)="fillMax(false)">
        <span>Max: {{ getMaxAmount(false).toFixed(6) }} {{ this.selectedMarket.first_token.shortName }} </span>
      </div>
    </div>
    <div class="address">
      <!-- <button
        *ngIf="!spotAddress"
        class="blueButton"
        (click)='newSpotAddress()'
      >Create Spot Address</button> -->
      <div class="address-info" *ngIf="spotAddress">
        <div class="key-value-pair">
          <div class="key">Address:</div>
          <div class="value">{{ spotAddress | shortAddress }}</div>
        </div>
        <div class="key-value-pair">
          <div class="key">{{ getNameBalanceInfo(selectedMarket.first_token)[0] }}:</div>
          <div class="value">{{ getNameBalanceInfo(selectedMarket.first_token)[1] }}</div>
        </div>
        <div class="key-value-pair">
          <div class="key">{{ getNameBalanceInfo(selectedMarket.second_token)[0] }}:</div>
          <div class="value">{{ getNameBalanceInfo(selectedMarket.second_token)[1] }}</div>
        </div>
        <div class="key-value-pair">
          <div class="key">Attestation:</div>
          <div class="value">{{ isSpotAddressSelfAtt() }}</div>
        </div>
        <div class="key-value-pair"></div>
      </div>
    </div>
    <div class="additional-info" *ngIf="spotAddress">
      <button
        class="blueButton"
        (click)='closeAll()'
      >Close All Orders</button>
      <button
        class="blueButton"
        (click)='transfer()'
      >Transfer</button>
    </div>
    <!-- <div class="liquidity">
      <div class="custom-form-field">
        <div class="prefix">Amount</div>
        <input type='number' #liquidity_amount>
        <div class="suffix">{{ selectedMarket.first_token.shortName }} / {{ selectedMarket.second_token.shortName }}</div>
      </div>
      <div class="custom-form-field">
        <div class="prefix">Number Buy/Sell Orders</div>
        <input type='number' #liquidity_orders>
        <div class="suffix"> </div>
      </div>
      <div class="custom-form-field">
        <div class="prefix">Max. Price Range</div>
        <input type='number' #liquidity_range>
        <div class="suffix">%</div>
      </div>
      <button
        class="blueButton"
        (click)='addLiquidity(liquidity_amount.value, liquidity_orders.value, liquidity_range.value)'
      >Add Liquidity</button>
      <button
        class="redButton"
        (click)='stopLiquidity()'
      >Stop Liquidity</button>
    </div> -->
  </mat-card-content>
</mat-card>

<div class="overlay-loading" *ngIf='isLoading'>
  <mat-spinner></mat-spinner>
</div>