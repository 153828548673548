<mat-card>
    <mat-card-title>
      <div class="header-wrapper">
        <div class="title-text">
          <span>Chart</span>
        </div>
        <div class="icons">
          <!-- <mat-icon svgIcon="resize"></mat-icon>
          <mat-icon svgIcon="close"></mat-icon> -->
        </div>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div #chart class="chart-container"></div>
    </mat-card-content>
</mat-card>