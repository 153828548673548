<div class="window-wrapper" [ngClass]='{ minimized: isMinimized }'>
    <div class="header">
        <div class="title"> {{ title }}</div>
        <div class="icons">
            <mat-icon (click)='minimize()'>minimize</mat-icon>
            <mat-icon *ngIf='closable' (click)='close()'>close</mat-icon>
        </div>
    </div>
    <div class="body">
        <ng-content></ng-content>
    </div>
    <div class="resize"></div>
</div>