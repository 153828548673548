<mat-card>
    <mat-card-title>
      <div class="header-wrapper">
        <div class="title-text">
          <span>Order History</span>
        </div>
        <div class="icons">
          <!-- <mat-icon svgIcon="resize"></mat-icon>
          <mat-icon svgIcon="close"></mat-icon> -->
        </div>
      </div>
    </mat-card-title>
    <mat-card-content>
        <div class="order-history-container">
            <table mat-table [dataSource]="tradeHistory">
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef>Price</th>
                  <td mat-cell *matCellDef="let el"> {{el.price.toFixed(4)}} </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>Amount</th>
                  <td mat-cell *matCellDef="let el"> {{el.amount.toFixed(4)}} </td>
                </ng-container>
                <ng-container matColumnDef="total">
                  <th mat-header-cell *matHeaderCellDef>Total</th>
                  <td mat-cell *matCellDef="let el"> {{(el.total).toFixed(4)}} </td>
                </ng-container>
                <ng-container matColumnDef="txid">
                  <th mat-header-cell *matHeaderCellDef>Tx</th>
                  <td mat-cell *matCellDef="let el"> 
                    <mat-icon (click)='copy(el.txid)'>reorder</mat-icon>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </div>
    </mat-card-content>
</mat-card>